/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/pekupeku.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/teletomy.gltf"
    );

    const { actions } = useAnimations(animations, group);
    //
    React.useEffect(() => {
        actions && actions['NlaTrack'].play();
    }, [actions]);
    

    const playBounceAnimation = () => {
        console.log("actions: ", actions);
        if(actions['SillyDance'].isRunning()) {
            actions['SillyDance'].stop();
            //play NlaTrack animation
            actions['NlaTrack'].play();
            //stop audio
            audio.pause();
            //reset audio
            audio.currentTime = 0;
        } else {
            audio.play();
            actions['SillyDance'].play();
        }
    }


    return (
        <group ref={group} onClick={()=>playBounceAnimation()} {...props} dispose={null}>
            <group name="Scene">
                <group name="rig002">
                    <group name="Dominik004">
                        <skinnedMesh
                            name="Basemesh_arshlevon_shoes005"
                            geometry={nodes.Basemesh_arshlevon_shoes005.geometry}
                            material={materials["Stras.002"]}
                            skeleton={nodes.Basemesh_arshlevon_shoes005.skeleton}
                            morphTargetDictionary={
                                nodes.Basemesh_arshlevon_shoes005.morphTargetDictionary
                            }
                            morphTargetInfluences={
                                nodes.Basemesh_arshlevon_shoes005.morphTargetInfluences
                            }
                        />
                        <skinnedMesh
                            name="Basemesh_arshlevon_shoes005_1"
                            geometry={nodes.Basemesh_arshlevon_shoes005_1.geometry}
                            material={materials["TeleMrfy_02.001"]}
                            skeleton={nodes.Basemesh_arshlevon_shoes005_1.skeleton}
                            morphTargetDictionary={
                                nodes.Basemesh_arshlevon_shoes005_1.morphTargetDictionary
                            }
                            morphTargetInfluences={
                                nodes.Basemesh_arshlevon_shoes005_1.morphTargetInfluences
                            }
                        />
                    </group>
                    <primitive object={nodes.root} />
                    <primitive object={nodes["MCH-torsoparent"]} />
                    <primitive object={nodes["MCH-hand_ikparentL"]} />
                    <primitive object={nodes["MCH-upper_arm_ik_targetparentL"]} />
                    <primitive object={nodes["MCH-hand_ikparentR"]} />
                    <primitive object={nodes["MCH-upper_arm_ik_targetparentR"]} />
                    <primitive object={nodes["MCH-foot_ikparentL"]} />
                    <primitive object={nodes["MCH-thigh_ik_targetparentL"]} />
                    <primitive object={nodes["MCH-foot_ikparentR"]} />
                    <primitive object={nodes["MCH-thigh_ik_targetparentR"]} />
                </group>
            </group>
            </group>
    );
}

useGLTF.preload("/teletomy.gltf");